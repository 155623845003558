'use strict'

###*
 # @ngdoc service
 # @name ReportVisualizersManager.factory:ReportVisualizersManager

 # @description

###
angular
  .module 'mundoReporting'
  .factory 'ReportVisualizersManager', [
    'MundoDefaultManager'
    '$q'
    'Restangular'
    (
      MundoDefaultManager
      $q
      Restangular
    ) ->
      ReportVisualizersManagerBase = new MundoDefaultManager()
      ReportVisualizersManagerBase.setUrl('reports/visualizers')

      ReportVisualizersManagerBase.getListCustom = ->
        sources = []
        Restangular.all(@conf.url).customGET("").then((data) ->
          for k, v of data.results
            sources.push(v)
          sources
        )
        sources

      ReportVisualizersManagerBase.getList = ->
        response = $q.defer()
        response.resolve @getListCustom()
        response.promise

      ReportVisualizersManagerBase
  ]
